import React from 'react'
import { Row,
  Col,
  Container} from 'reactstrap';

const Footer = () => (
  <footer>
  <div className="fotos text-center dunya">
  <Container>
<Row>
<Col sm="12" md={{ size: 10, offset: 1 }}>
<h3>İstanbul Web Tasarım</h3>
                <p>Sizler için tüm birikimimiz ve enerjimiz ile markanızın değerine değer katacak, doğrudan potansiyel müşterilerinize hitap edebilecek ,opsiyonel özellikler barındıran, estetikliğin şıklıkla buluştuğu <strong className="accent-color">Web Tasarım</strong> çalışmaları hazırlıyoruz.Yaptığımız çalışmalar öncelikli olarak kalıcı reklam niteliği taşıdığı gibi günün değil geleceğin teknolojileri ile hazırlanmaktadır.Mobil uyumlu (tablet telefon vb...),tarayıcılarda kusursuz açılan ve yönetim panelli siteleri oluşturmak bizim için bir angarya değil tamamen keyiftir.Bu hususta İstanbul Web Tasarım olarak 2012 den beri sizler ile çalışmak için her zaman hazır ve istekliyiz.</p>

</Col>
</Row>

  <Row>
                                    	<Col md="4">
										<div className="contact-service">
										<div className="dotted-line d-none d-md-block d-lg-block"></div>
										<div className="striped-icon-xlarge"><i className="fa fa-map" aria-hidden="true"></i></div>
										<p>Adres</p>
										  <div>
    <span>Gonca Sokak No:7 Görükle Mahallesi</span>
    <span>Nilüfer , Bursa</span>
  </div>
										</div>

                                        </Col>
                                       	<Col md="4">
										<div className="contact-service">
										<div className="dotted-line d-none d-md-block d-lg-block"></div>
										<div className="striped-icon-xlarge"><i className="fas fa-phone-volume"></i></div>
										<p>Telefon</p>
										<span>+90 506 659 65 30</span>
										</div>

                                        </Col>
										<Col md="4">
										<div className="contact-service">
										<div className="striped-icon-xlarge"><i className="far fa-envelope"></i></div>
										<p>E-Posta</p>
										<span>info@istanbulwebtasarim.com.tr</span>
										</div>

                                       </Col>
                                    </Row>

                                    <div className="copyright-section">
                                    <Row>
                                      <Col md="12">
                                      <div className="social-widget text-center">

                                                        <ul className="social-icons">
                                            <li>
                                              <a className="facebook" aria-label="facebook" href="https://www.facebook.com/istanbulwebdizayn"><i className="fab fa-facebook-f"></i></a>
                                            </li>
                                            <li>
                                              <a className="twitter" aria-label="Twitter" href="https://twitter.com/istanbultasari"><i className="fab fa-twitter"></i></a>
                                            </li>
                                            <li>
                                              <a className="google" aria-label="google" href="https://plus.google.com/u/0/b/109197534728568562668/+istanbulwebtasarimtr/posts"><i className="fab fa-google"></i></a>
                                            </li>
                                            <li>
                                              <a className="dribbble" aria-label="dribbble" href="/"><i className="fab fa-dribbble"></i></a>
                                            </li>
                                                            <li>
                                              <a className="linkdin" aria-label="linkdin" href="/"><i className="fab fa-linkedin"></i></a>
                                            </li>
                                                            <li>
                                              <a className="flickr" aria-label="flickr" href="/"><i className="fab fa-flickr"></i></a>
                                            </li>
                                                            <li>
                                              <a className="tumblr" aria-label="tumblr" href="/"><i className="fab fa-tumblr"></i></a>
                                            </li>
                                                            <li>
                                              <a className="instgram" aria-label="instagram" href="/"><i className="fab fa-instagram"></i></a>
                                            </li>
                                                            <li>
                                              <a className="vimeo" aria-label="vimeo" href="/"><i className="fab fa-vimeo"></i></a>
                                            </li>
                                            <li>
                                              <a className="skype" aria-label="skype" href="/"><i className="fab fa-skype"></i></a>
                                            </li>
                                          </ul>
                                        </div>
                                      </Col>
                                    </Row>
                                      <Row>
                                        <Col md="12" className="text-center">
                                          <p>© Copyright 2012 - Her Hakkı Saklıdır <a href="/">İstanbul Web Tasarım</a> - <a title="Hosting" href="https://www.control.com.tr">Hosting</a> - <a title="Sunucu Kiralama" href="https://www.control.com.tr/vds-kiralama" title="vds kiralama,sunucu kiralama">Sunucu Kiralama</a></p>
                                        </Col>

                                      </Row>
                                    </div>

                                    </Container>
                                    </div>
                                      </footer>

)

export default Footer
