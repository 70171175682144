import PropTypes from 'prop-types'
import React from 'react'
import { Link } from "gatsby"
import {Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Container
} from 'reactstrap'
import logo from '../images/logo.png'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <section className="headersection">
      <div className="maskebir">
      <div className="clearfix " id="topbar">
    <Container>
        <Row>
        <Col md="12">
          <div className="header-contact">
            <ul>
                                        <li className="phone-number">
                                        <a href="tel:+905066596530">
                                            <i className="fa fa-phone"></i> Tel : +90 (506) 659 65 30
                                        </a></li>
                                        <li className="mailto-email d-none-mob">
                                            <i className="fa fa-envelope"></i> Mail : info@istanbulwebtasarim.com.tr
                                        </li>
                  <li className="mailto-email d-none-mob">
                                            <i className="fa fa-comments"></i> Canlı Destek
                                        </li>

                                    </ul>

                            </div>

          </Col>
      </Row>
    </Container>
  </div>
      <div className="maskeiki">
      <Container>
      <Navbar light expand="md">
          <NavbarBrand aria-label="İstanbul Web Tasarım" href="/"><img src={logo} alt="İstanbul Web Tasarım"/></NavbarBrand>
          <NavbarToggler onClick={this.toggle} aria-label="NavbarTogler"/>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>

                  <Link activeClassName="active" className="nav-link" to="/">Anasayfa</Link>
              </NavItem>
              <NavItem>
              <Link activeClassName="active" className="nav-link" to="/kurumsal">Kurumsal</Link>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
               <DropdownToggle nav caret>
                 Hizmetlerimiz
               </DropdownToggle>
               <DropdownMenu right>

                 <DropdownItem href="/web-tasarim">Web Tasarım
                 </DropdownItem>
                 <DropdownItem href="/e-ticaret">E-Ticaret
                 </DropdownItem>
                 <DropdownItem href="/arama-motoru-optimizasyonu">Arama Motoru Optimizasyonu
                 </DropdownItem>
                 <DropdownItem href="/google-adwords">Google Adwords
                 </DropdownItem>
                 <DropdownItem href="/marka-tescili">Marka Tescili
                 </DropdownItem>
                 <DropdownItem href="/domain">Alan Adı Tescili
                 </DropdownItem>
                 <DropdownItem href="/hosting">Alan Adı Barındırma
                 </DropdownItem>

               </DropdownMenu>
             </UncontrolledDropdown>
              <NavItem>
                <Link activeClassName="active" className="nav-link" to="/referanslar">Referanslar</Link>
              </NavItem>
              <NavItem>
                <Link activeClassName="active" className="nav-link" to="/teklif-iste">Teklif İste</Link>
              </NavItem>
              <NavItem>
                <Link activeClassName="active" className="nav-link" to="/blog">Blog</Link>
              </NavItem>
              <NavItem>
                <Link activeClassName="active" className="nav-link" to="/iletisim">İletişim</Link>
              </NavItem>

            </Nav>
          </Collapse>
        </Navbar>
      </Container>
        </div>
      </div>
      </section>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
