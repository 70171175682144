import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container , Row , Col } from 'reactstrap'
import { graphql } from "gatsby"
import Img from "gatsby-image"


export default class AdwordsPage extends React.Component {
  render() {
    const data = this.props.data
        const pagebgadwords = data.pagebgamo.childImageSharp.fluid


return (
  <Layout pageTitle="Google Adwords">
  <SEO title="Google Adwords - Google Reklamları" keywords={[`istanbul Web Tasarım`, `Google Reklamları`, `Google Adwords`]} description="Özelleştirilmiş google adwords - google reklamları ile potansiyel müşterilerinize erişin" />

    <section className="bginpage">
    <Img fluid={pagebgadwords}
    style={{
                   position: 'absolute',
                   left: 0,
                   top: 0,
                   width: '100%',
                   height: '100%',
                 }} alt="Google Adwords Hizmeti"/>
    <Container>
      <Row>
        <Col md="5">
        <div className="bginpagedark">
        <h1 className="mb20  section-title">
        Google Adwords
             </h1>
             <p>Google Adwords ile potansiyel müşterilinize ulaşın.Minumum maliyet ile maximum verim politakası ,kaliteli adwords gösterimi ve çok daha fazlası için Google Adwords hizmetimiz ile tanışmaya davetlisiniz..</p>
      </div>  </Col>
        </Row>
        </Container>
  </section>
  <section className="maincontent">
  <Container>

    <Row>
      <Col md="12" className="acik">
      <h1 className="font-size-normal  section-title">
               <small>Web Tasarım &amp; E-Ticaret &amp; Arama Motoru Optimizasyonu &amp; Kurumsal e-Posta</small>
               İstanbul Web Tasarım
                <div className="bar"></div>
           </h1>

        <p>
    Google Adwords nedir? Ne İşe Yarar?
    Google adwords google arama motorlarında sizlerin belirleyeceğiniz fiyatlarla arama kelimelerinizin sponsorlu bağlantılar kısmında yani sıralamaların üst kısmında yer alması işlemi olarak tanımlayabılırız.Bu noktada google adwords te kendi bütçenizi kendiniz oluşturmuş kendi hedef kelimelerinizde kendiniz stratejilerinizi belirliyebilirsiniz.Önemli olan ise bu stratejilerde en az maliyetle en çok karı elde etmektir.
    Nasıl çalışır ?

    Reklamlarınızı siz oluşturursunuz
    Reklam oluşturup işletmenizle alakalı kelime veya öbekler olan anahtar kelimeler seçersiniz. Anahtar kelimeler için fikir alın

    Reklamlarınız Google’da görünür
    Kullanıcılar anahtar kelimelerinizden birini kullanarak Google’da arama yaptığında, reklamınız arama sonuçlarının yanında görünebilir. Artık, sizinle zaten ilgilenen bir hedef kitleye reklam yapabilirsiniz.

    Müşteri çekersiniz
    Kullanıcılar, reklamınızı tıkladıktan sonra bir satın alma işlemi yapabilir veya sizinle ilgili daha fazla bilgi edinebilir.
      </p>
    </Col>
    </Row>
    </Container>
    </section>
  </Layout>
)

}
}
export const pageQuery = graphql`
query {
pagebgamo: file(
 relativePath: { regex: "/bg7.jpg/" }
) {
childImageSharp {
           fluid(quality: 100){
             ...GatsbyImageSharpFluid_withWebp
           }
         }
}
}
`
